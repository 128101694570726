.App {
  width: 90%;
  margin: 0 auto;
  color: #333;
}

.App-Logo {
  left: 5%;
}

.Insta-Logo {
  text-align: right;
  right: 3%;
}

.App-Logo, .Insta-Logo {
  top: 30px;
  z-index: 2;
  position: absolute;
}

.App-Logo img, .Insta-Logo img {
  max-width: 8%;
}


@media (max-width: 45.5em) {
  .App {
    width: 90%;
  }

  .App-Logo, .Insta-Logo {
    top: 12px;
  }

  .App-Logo {
    display: none;
  }

  .App-Logo img, .Insta-Logo img {
    max-width: 11%;
  }

}
